/**
 * Representation of a query, which consists of a premise and a hypothesis
 */
export class Query {
    constructor(readonly premise: string = '', readonly hypothesis: string = '') {}

    // defines how the URL will identify the parameters we want to store
    private static readonly premiseURLKey = 'p';
    private static readonly hypothesisURLKey = 'h';

    /**
     * Returns whether the current query has any populated values.
     * This catches scenarios where rogue url strings are provided /?random=badValue
     *
     * @return {boolean}
     */
    isValid() {
        return this.premise || this.hypothesis;
    }

    /**
     * Serializes the query to a URL appropriate representation.
     *
     * @returns {string}
     */
    toQueryString(): string {
        const params = new URLSearchParams();
        params.set(Query.premiseURLKey, this.premise);
        params.set(Query.hypothesisURLKey, this.hypothesis);
        return params.toString();
    }

    /**
     * Factory that returns a Query instance based upon the provided URL search parameters.
     *
     * @param {string} searchParams
     *
     * @returns {Query}
     */
    public static fromQueryString(searchParams: string): Query {
        const params = new URLSearchParams(searchParams);
        return new Query(
            params.get(Query.premiseURLKey) ?? '',
            params.get(Query.hypothesisURLKey) ?? ''
        );
    }
}
