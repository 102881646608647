/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, BrowserRouter, Route } from 'react-router-dom';
import { Header, Content, Footer, Layout } from '@allenai/varnish';
import { FilePdfOutlined, TrophyOutlined, DatabaseOutlined, GithubOutlined, RobotOutlined } from '@ant-design/icons';
import MediaQuery from 'react-responsive';

import Menu from 'antd/es/menu';

import { Home } from './pages/Home';
import { AppRoute } from './AppRoute';

import logoSrc from './logo_cropped.png';
const s2Url = 'https://www.semanticscholar.org/paper/WANLI%3A-Worker-and-AI-Collaboration-for-Natural-Liu-Swayamdipta/6b48ff31f00517f66ed4135820f96073f310e567'
const dataUrl = 'https://allenai.org/data/wanli';
const codeUrl = 'https://github.com/alisawuffles/wanli';
const lbUrl = 'https://leaderboard.allenai.org/wanli/';
const modelUrl = 'https://huggingface.co/alisawuffles/roberta-large-wanli'

/**
 * An array capturing the available routes in your application. You can
 * add or remove routes here.
 */
const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Home',
        component: Home,
    },
];

export const App = (props: RouteComponentProps) => {
    return (
        <BrowserRouter>
            <Route path="/">
                <Layout bgcolor="white">
                    <Header>
                        <Header.Columns columns="auto 1fr 78px 70px 80px 74px 128px">  {/* manually spaced to look nice */}
                            <Header.Logo label={<Header.AppName>WANLI</Header.AppName>}>
                                <SimpleLogo>
                                    <Logo />
                                </SimpleLogo>
                            </Header.Logo>
                            <span />
                            <Menu>
                                {s2Url ? (
                                    <a href={s2Url}>
                                        <FilePdfOutlined />
                                        <MediaQuery minWidth={576}>
                                            <MenuText>Paper</MenuText>
                                        </MediaQuery>
                                    </a>
                                ) : null}
                            </Menu>
                            <Menu>
                                {dataUrl ? (
                                    <a href={dataUrl}>
                                        <DatabaseOutlined />
                                        <MediaQuery minWidth={576}>
                                            <MenuText>Data</MenuText>
                                        </MediaQuery>
                                    </a>
                                ) : null}
                            </Menu>
                            <Menu>
                                {modelUrl ? (
                                    <a href={modelUrl}>
                                        <RobotOutlined />
                                        <MediaQuery minWidth={576}>
                                            <MenuText>Model</MenuText>
                                        </MediaQuery>
                                    </a>
                                ) : null}
                            </Menu>
                            <Menu>
                                {codeUrl ? (
                                    <a href={codeUrl}>
                                        <GithubOutlined />
                                        <MediaQuery minWidth={576}>
                                            <MenuText>Code</MenuText>
                                        </MediaQuery>
                                    </a>
                                ) : null}
                            </Menu>
                            <Menu>
                                {lbUrl ? (
                                    <a href={lbUrl}>
                                        <TrophyOutlined />
                                        <MediaQuery minWidth={576}>
                                            <MenuText>Leaderboard</MenuText>
                                        </MediaQuery>
                                    </a>
                                ) : null}
                            </Menu>
                        </Header.Columns>
                    </Header>
                    <Content main>
                        {ROUTES.map(({ path, component }) => (
                            <Route key={path} path={path} exact component={component} />
                        ))}
                    </Content>
                    <Footer />
                </Layout>
            </Route>
        </BrowserRouter>
    );
};

const Logo = styled.img.attrs({
    src: logoSrc,
    alt: 'WANLI Logo',
    height: '54px'
})``;

const MenuText = styled.span`
    margin-left: ${({ theme }) => theme.spacing.xs2};
`;

const SimpleLogo = styled.div`
    border-radius: 25px;
    width: 50px;
    height: 50px;
    line-height: 1;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: ${({ theme }) => theme.color.B2};
`;

const OverflowHidden = styled.div`
    overflow: hidden;
`;
